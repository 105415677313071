@tailwind base;
@tailwind components;
@tailwind utilities;

/* ATTACHED FONT  */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* @font-face {
  font-family: "JetBrains";
  src: url("../public/assets/font/JetBrainsMono-Regular.ttf");
} */
/* @font-face {
  font-family: "Barlow";
  src: url("../public/assets/font/Barlow-Regular.ttf");
} */
/* @font-face {
  font-family: "Barlow";
  src: url("../public/assets/font/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Barlow-Medium";
  src: url("../public/assets/font/Barlow-Medium.ttf");
}
@font-face {
  font-family: "JetBrains-Medium";
  src: url("../public/assets/font/JetBrainsMono-Medium.ttf");
} */

html {
  scroll-padding-top: 100px; /* Adjust this value as needed */
}

body {
  margin: 0;
  /* font-family: "Barlow", sans-serif; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-size: 1rem;
  line-height: 1.6em; */
  letter-spacing: 0.2px;
  word-spacing: 0.2px;
}

/* LINE CLAMPED 3====== */
.clamped-text {
  display: -webkit-box; /* Establish flexbox for the element */
  -webkit-box-orient: vertical; /* Set the box orientation to vertical */
  -webkit-line-clamp: 3; /* Limit the content to 3 lines */
  overflow: hidden; /* Hide the overflowing content */
  text-overflow: ellipsis; /* Add ellipsis (...) at the end */
}

/* FILE ICONS-4 A-TAGS  */
.icon {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  margin-right: 5px;
}

/* LOAIDNG ANIMATION =========
============================== */
.loading {
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  /* color: #3498db; */
  display: flex;
}

.letter {
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out infinite;
}

.letter:nth-child(1) {
  animation-delay: 0.1s;
}
.letter:nth-child(2) {
  animation-delay: 0.2s;
}
.letter:nth-child(3) {
  animation-delay: 0.3s;
}
.letter:nth-child(4) {
  animation-delay: 0.4s;
}
.letter:nth-child(5) {
  animation-delay: 0.5s;
}
.letter:nth-child(6) {
  animation-delay: 0.6s;
}
.letter:nth-child(7) {
  animation-delay: 0.7s;
}
.letter:nth-child(8) {
  animation-delay: 0.8s;
}
.letter:nth-child(9) {
  animation-delay: 0.9s;
}
.letter:nth-child(10) {
  animation-delay: 1s;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* LOAIDNG ANIMATION -END =========
============================== */

/* clicked - COLOR HIGHLIGHT  */
/* .highlight {
  background-color: yellow;
  transition: background-color 0.3s ease;
} */

.HeightHome {
  /* height: calc(100vh - 56px); */
  height: calc(100vh - 106px);
  /* height: calc(100vh - 86px); */
  /* height: calc(100vh - 200px); */
}
/* .HeightMiddleHome {
  height: calc(100vh - 100px);
} */
.HeightMiddle {
  height: calc(100vh - 56px);
  /* height: calc(100vh - 96px); */
  /* height: calc(100vh - 110px); */
}
.When-Footer-Occured-HeightMiddle {
  height: calc(100vh - 56px);
  /* height: calc(100vh - 96px); */
  /* height: calc(100vh - 110px); */
}
.searchHeight {
  max-height: calc(100vh - 140px);
}
.active{
  background: orangered;
  padding: 1rem;
}
.rightMenu div.active {
  font-weight: bold;
}
.pageHeadingOnRight li.active {
  font-weight: bold;
}

/* SCROLL-BAR ========= */

/* LEFT SIDE SCROLLBAR  */
/* Light-Mode-LEFT:  */
.scrollBarLeft {
  -webkit-overflow-scrolling: touch;
}
.scrollBarLeft::-webkit-scrollbar {
  width: 10px;
  height: 44px;
  background-color: #eff3f8;
  border-radius: 4px;
  /* display: none; */
  -webkit-overflow-scrolling: touch;
}

.scrollBarLeft::-webkit-scrollbar-thumb {
  background: #c9c5c5;
  border-radius: 4px;
}
.scrollBarLeft::-webkit-scrollbar-thumb:hover{
  background: #b6b3b3;
}
/* DARK-Mode-LEFT:  */
.scrollBar-dark-left {
  -webkit-overflow-scrolling: touch;
}
.scrollBar-dark-left::-webkit-scrollbar {
  width: 10px;
  height: 44px;
  background-color: #111;
  border-radius: 4px;
  /* display: none; */
  -webkit-overflow-scrolling: touch;
}

.scrollBar-dark-left::-webkit-scrollbar-thumb {
  background: #525252;
  border-radius: 4px;
}
.scrollBar-dark-left::-webkit-scrollbar-thumb:hover{
  background: #616161;
}

/* RIGHT SIDE SCROLLBAR  */
/* Light-Mode:  */
.scrollBar {
  -webkit-overflow-scrolling: touch;
}
.scrollBar::-webkit-scrollbar {
  width: 10px;
  height: 44px;
  background-color: #fff;
  border-radius: 4px;
  /* display: none; */
  -webkit-overflow-scrolling: touch;
}

.scrollBar::-webkit-scrollbar-thumb {
  background: #c9c5c5;
  border-radius: 4px;
}
.scrollBar::-webkit-scrollbar-thumb:hover{
  background: #b6b3b3;
}
/* .scrollBar-container:hover .scrollBar::-webkit-scrollbar {
  display: block;
  -webkit-overflow-scrolling: touch;
} */

/* Dark-Mode:  */
.scrollBar-dark {
  -webkit-overflow-scrolling: touch;
}
.scrollBar-dark::-webkit-scrollbar {
  width: 10px;
  height: 44px;
  background-color: #262626;
  border-radius: 4px;
  /* display: none; */
  -webkit-overflow-scrolling: touch;
}

.scrollBar-dark::-webkit-scrollbar-thumb {
  background: #525252;
  border-radius: 4px;
}
.scrollBar-dark::-webkit-scrollbar-thumb:hover{
  background: #616161;
}
/* SCROLL BAR END ========== */

/* TAILWIND - CSS SCROLL-BAR =========
============================= */
.scrollbar-hide {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Show scrollbar on hover */
.scrollbar-hover:hover::-webkit-scrollbar {
  display: block;
}

/* PRINT STYLE  */
@media print {
  body {
    visibility: hidden;
  }

  .print-container {
    visibility: visible;
  }
}

/* MIDDLE CONTENT =======
======================== */